.cell-number {
  text-align: right;
}

.table-container {
  overflow-x: scroll;
}

.table-selectable {
  tbody tr {
    cursor: pointer;
  }
}

hr.totalizer {
  border-top: 1px double black;
  margin: 2px;
}

.table-header th {
  background: #ffc000;
}

.grand-totals td {
  background: #ddebf7;
}

.subtotals td {
  background: #ffe699;
}

.chart-container {
  width: 100%;
  min-width: 768px;
  overflow-x: auto;
  margin-top: 30px;

  .table-chart-legends {
    display: none;
    flex-direction: column-reverse;

    .chart-data tr td {
      padding: 3px;
      text-align: left;
    }
  }

  .table-chart-data {
    table-layout: fixed;

    tr td {
      padding: 3px;
      text-align: center;
    }
  }
}

@media (max-width: 1024px) {
  table {
    font-size: 0.875rem;
  }
}
