@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-Thin.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-Thin.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-ThinItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-ThinItalic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-ExtraLight.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-ExtraLight.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-ExtraLightItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-ExtraLightItalic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-Light.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-Light.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-LightItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-LightItalic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-Regular.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-Regular.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-Italic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-Italic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-Medium.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-Medium.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-MediumItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-MediumItalic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-SemiBold.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-SemiBold.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-SemiBoldItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-SemiBoldItalic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-Bold.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-Bold.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-BoldItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-BoldItalic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-ExtraBold.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-ExtraBold.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-ExtraBoldItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-ExtraBoldItalic.woff?3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-Black.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-Black.woff?3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/inter_web/Inter-BlackItalic.woff2?3.13") format("woff2"),
       url("/fonts/inter_web/Inter-BlackItalic.woff?3.13") format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: normal;
  // font-named-instance: 'Regular';
  font-display: swap;
  src: url("/fonts/inter_web/Inter-roman.var.woff2?3.13") format("woff2");
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-style: italic;
  // font-named-instance: 'Italic';
  font-display: swap;
  src: url("/fonts/inter_web/Inter-italic.var.woff2?3.13") format("woff2");
}

/* Legacy naming compatibility */
@font-face {
  font-family: 'Inter var alt';
  font-weight: 100 900;
  font-style: normal;
  // font-named-instance: 'Regular';
  font-display: swap;
  src: url("/fonts/inter_web/Inter-roman.var.woff2?3.13") format("woff2");
}
@font-face {
  font-family: 'Inter var alt';
  font-weight: 100 900;
  font-style: italic;
  // font-named-instance: 'Italic';
  font-display: swap;
  src: url("/fonts/inter_web/Inter-italic.var.woff2?3.13") format("woff2");
}


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  src: url("/fonts/inter_web/Inter.var.woff2?3.13") format("woff2");
}


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  src: url("fonts/inter_web/Inter.var.woff2?3.13") format("woff2");
}

html, body {
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  html, body {
    font-family: "Inter var", sans-serif;
  }
}

// Font features

.font-feature-tab {
  font-feature-settings: "tnum" 1;
}
