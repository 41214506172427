.toast-close-button {
  @extend .close;
}

.toast {
  opacity: 1 !important;
  background-image: unset !important;
  box-shadow: none !important;
  color: $body-color !important;
}

#toast-container > .toast {
  background-image: none !important;
}

.toast.toast-error {
  border-left: $danger solid 6px;
}

.toast.toast-info {
  border-left: $info solid 6px;
}

.toast.toast-warning {
  border-left: $warning solid 6px;
}

.toast.toast-success {
  border-left: $success solid 6px;
}

#toast-container > div:hover {
  box-shadow: none !important;
}
