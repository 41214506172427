/**
 * Taken from the ace template of the CRM-VI project.
 * Classes removed:
 *   - no-skin
 */
.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-list > li,
.nav-list > li > a,
.nav-list .nav-header {
  margin: 0;
}
.nav-list li > a:focus {
  outline: none;
}
.nav-list > li {
  display: block;
  position: relative;
  float: none;
  padding: 0;
  border-style: solid;
  border-width: 1px 0 0;
}
.nav-list > li:last-child {
  border-bottom-width: 1px;
}
.nav-list > li.active > a {
  font-weight: bold;
}
.nav-list > li > a {
  display: block;
  height: 39px;
  line-height: 17px;
  padding-left: 7px;
  text-shadow: none !important;
  font-size: 13px;
  text-decoration: none;
}
.nav-list > li > a > .menu-icon {
  display: inline-block;
  min-width: 30px;
  margin-right: 2px;
  vertical-align: sub;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
}
.nav-list > li:before {
  display: none;
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 1;
  height: 41px;
  width: 3px;
  max-width: 3px;
  overflow: hidden;
  background-color: #629CC9;
}
@media only screen and (min-width: 992px) {
  .main-container.container .sidebar:not(.h-sidebar) .nav-list > li:before {
    left: -2px;
  }
}
.main-container.container .sidebar.sidebar-fixed:not(.h-sidebar) .nav-list > li:before {
  left: 0;
}
.nav-list > li:hover:before {
  display: block;
}
.nav-list > li a > .arrow {
  display: block;
  width: 14px !important;
  height: 14px;
  line-height: 14px;
  text-shadow: none;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 12px;
  padding: 0;
  text-align: center;
}
.nav-list > li.separator {
  height: 3px;
  background-color: transparent;
  position: static;
  margin: 1px 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.nav-list > li .submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  position: relative;
}
.nav-list > li .submenu > li {
  margin-left: 0;
  position: relative;
}
.nav-list > li .submenu > li > a {
  display: block;
  position: relative;
  padding: 7px 0 9px 37px;
  margin: 0;
  border-top-width: 1px;
  border-top-style: dotted;
}
.nav-list > li .submenu > li > a:hover {
  text-decoration: none;
}
.nav-list > li .submenu > li a > .menu-icon {
  display: none;
  font-size: 12px;
  font-weight: normal;
  width: 18px;
  height: auto;
  line-height: 12px;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 11px;
  z-index: 1;
  background-color: inherit;
}
.nav-list > li .submenu > li.active > a > .menu-icon,
.nav-list > li .submenu > li:hover > a > .menu-icon {
  display: inline-block;
}
.nav-list > li > .submenu {
  border-top: 1px solid;
}
.nav-list > li > .submenu > li:before {
  content: "";
  display: block;
  width: 7px;
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 17px;
  border: 1px dotted;
  border-width: 1px 0 0;
}
.nav-list > li > .submenu:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 18px;
  top: 0;
  bottom: 0;
  border: 1px dotted;
  border-width: 0 0 0 1px;
}
.nav-list li:hover > a > .arrow,
.nav-list li.active > a > .arrow,
.nav-list li.open > a > .arrow {
  color: inherit;
}
.nav-list li.open > .submenu,
.nav-list > li > .submenu li.open > .submenu {
  display: block;
}
.nav-list > li .submenu.collapsing {
  display: block;
}
no_such_element:-o-prefocus,
.nav-list > li > a > .menu-icon {
  vertical-align: middle;
}
.nav-list > li > .submenu li > .submenu {
  display: none;
}
.nav-list > li > .submenu li.active > .submenu,
.nav-list > li > .submenu li > .submenu.collapsing {
  display: block;
}
.nav-list > li > .submenu a > .arrow {
  right: 10px;
  top: 10px;
  font-size: 16px;
}
.nav-list > li > .submenu > li:first-child > a {
  border-top-width: 0;
}
.nav-list > li > .submenu li > .submenu > li {
  line-height: 18px;
}
.nav-list > li > .submenu li > .submenu > li:before {
  display: none;
}
.nav-list > li > .submenu li > .submenu > li > a {
  margin-left: 20px;
  padding-left: 22px;
}
.nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
  margin-left: 20px;
  padding-left: 38px;
}
.nav-list > li > .submenu li > .submenu > li a > .menu-icon {
  display: inline-block;
  width: auto;
  position: static;
  background-color: transparent;
  margin-right: 4px;
  color: inherit;
}
.nav-list > li > .submenu li > .submenu > li a .menu-icon,
.nav-list > li > .submenu li > .submenu > li a .arrow {
  color: inherit;
}
.nav-list > li > .submenu li.open:not(.hover) > a > .menu-icon {
  display: inline-block;
}
.nav-list a .badge,
.nav-list a .label {
  position: absolute;
  top: 9px;
  right: 10px;
  opacity: 0.88;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
}
.nav-list a .badge .ace-icon,
.nav-list a .label .ace-icon {
  vertical-align: middle;
  margin: 0;
}
.nav-list a.dropdown-toggle .badge,
.nav-list a.dropdown-toggle .label {
  right: 26px;
}
.nav-list li:hover > a .badge,
.nav-list li:hover > a .label {
  opacity: 1;
}
.nav-list .submenu .submenu a .badge,
.nav-list .submenu .submenu a .label {
  top: 6px;
}
.sidebar.sidebar-fixed {
  position: fixed;
  top: auto;
  float: none !important;
  z-index: 1027;
}
.sidebar.sidebar-fixed:before {
  height: 5000px;
  top: auto;
  bottom: auto;
}
.sidebar-scroll-native {
  overflow-y: scroll !important;
  overflow-x: hidden;
}
body.mob-safari .sidebar.sidebar-fixed {
  top: 45px;
}
@media (max-width: 479px) {
  body.mob-safari .navbar-fixed-top:not(.navbar-collapse) + .main-container .sidebar-fixed {
    top: 90px;
  }
}
@media (max-width: 991px) {
  body.mob-safari .navbar-fixed-top + .main-container .sidebar.responsive {
    top: 45px;
  }
}
@media (max-width: 479px) {
  body.mob-safari .navbar-fixed-top + .main-container .sidebar.responsive {
    top: 90px;
  }
}
/**
//http://updates.html5rocks.com/2012/09/Stacking-Changes-Coming-to-position-fixed-elements
//webkit & Chrome22+ special case
//webkit is not following the standard specs as of Chrome22+
@media screen and (-webkit-min-device-pixel-ratio:0) {
  //like it happens in Firefox&IE, we expect when we set "z-index:auto" for fixed position .sidebar,
  //the child .submenu elements "z-index" is independent
  //and for example a "z-index:1031" .submenu appears on top of a fixed .navbar with "z-index:1030"
  //and a "z-index:1010" .popover appears on top of ".sidebar" but below ".submenu"
  //but webkit defines a new stacking context on "fixed" position elements(.sidebar here)
  //and the above doesn't happen, in fact .submenu inside fixed .sidebar with "z-index:auto" will appear below everything
  //so we set a good(?) "z-index" for .sidebar, but still it's not as good as standard specs (such as in Firefox)
  //and we may need other workarounds!
  //and you may change it based on your needs

  //it's possible have standard functionality in Chrome by going to:
  //chrome://flags/#fixed-position-creates-stacking-context
  //changing "Fixed position elements create stacking contexts" to "Disabled"
  //and removing the following rules
  //and assignning a higher "@submenu-zindex"

  .sidebar.sidebar-fixed {
    z-index: @zindex-sidebar-fixed;//???!

    &::before {
      height: 5000px;
      top: auto;
      bottom: auto;
    }
    }
}
*/
@media only screen and (max-width: 991px) {
  .responsive.sidebar-fixed {
    left: auto;
    margin-left: auto;
  }
  .navbar-fixed-top + .main-container > .responsive {
    position: fixed;
    left: auto;
    margin-left: auto;
  }
  .navbar-fixed-top + .main-container > .menu-toggler + .responsive {
    position: absolute;
    left: 190px;
    margin-left: -190px;
  }
  .navbar-fixed-top + .main-container > .menu-toggler + .responsive.sidebar-fixed {
    position: fixed;
    left: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar.responsive.sidebar-fixed {
    position: fixed;
    left: auto;
    margin-left: auto;
  }
  .main-container .menu-toggler.fixed {
    position: fixed;
    left: auto;
    z-index: 1026;
  }
  .sidebar.sidebar-fixed.responsive-max {
    position: fixed;
    left: auto;
    margin-left: auto;
  }
}
.sidebar .sidebar-toggle {
  border-style: solid;
  border-width: 0 0 1px;
  text-align: center;
  padding: 3px 0;
  position: relative;
}
.sidebar .sidebar-toggle > .ace-icon {
  padding: 0 5px;
  line-height: 18px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 100%;
  border: 1px solid;
  position: relative;
}
.sidebar .sidebar-toggle:before {
  content: "";
  display: block;
  height: 0;
  border-top: 1px solid;
  border-color: inherit;
  position: absolute;
  left: 15px;
  right: 15px;
  top: 13px;
}
.sidebar .sidebar-shortcuts {
  text-align: center;
  min-height: 40px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  border: 0 solid;
}
.sidebar .sidebar-shortcuts:empty {
  display: none;
}
.sidebar .sidebar-shortcuts-large {
  line-height: 37px;
}
.sidebar .sidebar-shortcuts-large > .btn {
  text-align: center;
  width: 41px;
  line-height: 24px;
  padding: 0;
  border-width: 4px;
}
.sidebar .sidebar-shortcuts-large > .btn > .ace-icon {
  margin: 0;
}
.sidebar .sidebar-shortcuts-mini {
  display: none;
  font-size: 0;
  width: 42px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.sidebar .sidebar-shortcuts-mini > .btn {
  border-width: 0;
  font-size: 0;
  line-height: 0;
  padding: 8px;
  margin: 1px;
  border-radius: 0;
  opacity: 0.85;
  filter: alpha(opacity=85);
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .sidebar ::safari-only,
  .sidebar .sidebar-shortcuts-mini > .btn {
    margin: 0;
  }
}
.nav-list li.active > a:after {
  display: block;
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  border: 8px solid transparent;
  border-width: 14px 10px;
}
.nav-list li.open > a:after {
  display: none;
}
.nav-list li.active.no-active-child > a:after {
  display: block;
}
.nav-list > li li.active > a:after {
  top: 2px;
  border-width: 14px 8px;
}
.nav-list li.active:not(.open) li.active > a:after {
  display: none !important;
}
@media (min-width: 992px) {
  .sidebar.compact,
  .sidebar.compact.navbar-collapse {
    width: 105px;
  }
  .sidebar.compact .sidebar-shortcuts {
    max-height: 40px;
  }
  .sidebar.compact .nav-list > li:before {
    height: auto;
    bottom: -1px;
  }
  .sidebar.compact .nav-list > li > a {
    height: auto;
    line-height: 16px;
    padding: 2px 0 8px;
    text-align: center;
  }
  .sidebar.compact .nav-list > li > a > .menu-icon {
    display: block;
    margin: 0;
    vertical-align: inherit;
    line-height: 32px;
    height: 32px;
    font-size: 20px;
  }
  .sidebar.compact .nav-list > li > a > .arrow {
    display: none;
  }
  .sidebar.compact .nav-list a .badge,
  .sidebar.compact .nav-list a .label {
    right: 12px;
  }
  .sidebar.compact .nav-list > li.active > .submenu,
  .sidebar.compact .nav-list > li.open > .submenu {
    display: none;
  }
  .sidebar.compact + .main-content {
    margin-left: 105px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .sidebar.compact + .main-content {
    margin-left: 0;
  }
  .sidebar.navbar-collapse.compact .nav-list > li > a {
    text-align: left;
    height: 39px;
    line-height: 37px;
    padding: 0 16px 0 7px;
  }
  .sidebar.navbar-collapse.compact .nav-list > li > a > .menu-icon {
    display: inline-block;
    vertical-align: sub;
    height: auto;
    line-height: inherit;
    margin: 0 2px 0 0;
    font-size: 18px;
  }
  .sidebar.navbar-collapse.compact .nav-list > li > a > .menu-text {
    display: inline;
  }
  .sidebar.navbar-collapse.compact .nav-list > li > a > .arrow {
    display: inline;
  }
  .sidebar.navbar-collapse.compact + .main-content {
    margin-left: 0;
  }
}
@media only screen and (min-width: 992px) {
  .nav-list li.hover > .submenu {
    position: absolute;
    left: 100%;
    margin-left: -2px;
    top: -10px;
    bottom: auto;
    z-index: 1026;
    width: 190px;
    border: 1px solid;
    display: none !important;
  }
  .nav-list li.hover:hover > .submenu,
  .nav-list li.hover.hover-show > .submenu {
    display: block !important;
  }
  .nav-list li.hover > .submenu:before,
  .nav-list li.hover > .submenu > li:before {
    display: none;
  }
  .nav-list li.hover.active > a:after {
    display: block;
  }
  .nav-list li.hover.active:hover > a.dropdown-toggle:after,
  .nav-list li.hover.active.hover-show > a.dropdown-toggle:after {
    display: none;
  }
  .nav-list li.hover .submenu > li.active > a:after {
    display: none !important;
  }
  .nav-list li.hover > .submenu.bottom {
    top: auto;
    bottom: -10px;
  }
  .nav-list li.hover > .submenu.bottom:before,
  .nav-list li.hover > .submenu.bottom:after {
    top: auto;
    bottom: 18px;
  }
  .nav-list li.hover > .submenu {
    padding: 0 2px;
  }
  .nav-list li.hover > .submenu > li > a {
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 18px;
    border-top-width: 0;
  }
  .nav-list > li.hover > .submenu > li.active > a {
    font-weight: bold;
  }
  .nav-list > li > .submenu li.hover > .submenu > li > a {
    padding-left: 12px !important;
    margin-left: auto !important;
  }
  .nav-list > li > .submenu li.hover > .submenu > li > a > .menu-icon {
    margin-right: 2px;
  }
  .nav-list > li .submenu > li.hover > a {
    padding-left: 22px;
  }
  .nav-list > li .submenu > li.hover > a > .menu-icon {
    left: 4px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .sidebar.navbar-collapse .nav-list li.hover > .submenu {
    position: relative;
    left: auto;
    top: auto;
    z-index: auto;
    margin: auto;
    padding: 0;
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-width: 0;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu > li:before,
  .sidebar.navbar-collapse .nav-list li.hover > .submenu:before {
    display: block;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu li > a {
    padding-left: 37px;
  }
  .sidebar.navbar-collapse .nav-list > li.hover > .submenu {
    border-top-width: 1px;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu > li > a > .menu-icon {
    background-color: inherit;
    margin-right: auto;
    width: 18px;
    position: absolute;
  }
  .sidebar.navbar-collapse .nav-list > li .submenu > li.hover > a > .menu-icon {
    left: 10px;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu > li.active > a {
    font-weight: normal;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu > li:hover > a > .menu-icon,
  .sidebar.navbar-collapse .nav-list li.hover > .submenu > li.hover-show > a > .menu-icon,
  .sidebar.navbar-collapse .nav-list li.hover > .submenu > li.active > a > .menu-icon {
    display: inline-block;
  }
  .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu > li > a {
    padding-left: 22px !important;
    margin-left: 20px !important;
  }
  .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu > li > a > .menu-icon {
    display: inline-block;
    width: auto;
    position: static;
    background-color: transparent;
    margin-right: 4px;
  }
  .sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a {
    margin-left: 20px !important;
    padding-left: 38px !important;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu > li > a {
    border-top-width: 1px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .sidebar.navbar-collapse .nav-list > li.hover > .submenu > li:first-child > a {
    border-top-width: 0;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu,
  .sidebar.navbar-collapse .nav-list li.hover:hover > .submenu,
  .sidebar.navbar-collapse .nav-list li.hover.hover-show > .submenu,
  .sidebar.navbar-collapse .nav-list li.hover > .arrow {
    display: none !important;
  }
  .sidebar.navbar-collapse .nav-list li.hover.active > .submenu {
    display: block !important;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu.nav-hide {
    display: none !important;
  }
  .sidebar.navbar-collapse .nav-list li.hover > .submenu.nav-show {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .sidebar.navbar-collapse.compact .nav-list > li.hover.active > a:after {
    display: none;
  }
}
.nav-list li > .arrow {
  display: none;
  position: absolute;
  top: 8px;
  right: 1px;
  z-index: 1027;
}
.nav-list li > .arrow:before,
.nav-list li > .arrow:after {
  border: 1px solid transparent;
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  left: -19px;
  top: 0;
  right: auto;
  bottom: auto;
  content: "";
  border-width: 10px;
}
.nav-list li > .arrow:before {
  z-index: 1;
  left: -20px;
}
.nav-list li > .arrow:after {
  z-index: 2;
}
.nav-list li:hover > a + .arrow,
.nav-list li.hover-show > a + .arrow {
  display: none;
}
@media only screen and (min-width: 992px) {
  .nav-list li.hover:hover > a.dropdown-toggle + .arrow,
  .nav-list li.hover.hover-show > a.dropdown-toggle + .arrow {
    display: block;
  }
}
.sidebar.menu-min {
  /**
  .nav-list > li.pull_up > .arrow {
   z-index: @zindex-submenu + 1;
   &:after , &:before {
    border-width: 10px;
    left: -20px;
   }
   &:before {
    left: -21px;
  }
  }
  */
}
.sidebar.menu-min,
.sidebar.menu-min.compact,
.sidebar.menu-min.navbar-collapse {
  width: 43px;
}
.sidebar.menu-min + .main-content {
  margin-left: 43px;
}
.sidebar.menu-min .nav-list a .badge,
.sidebar.menu-min .nav-list a .label {
  position: relative;
  top: -1px;
  right: auto;
  left: 4px;
}
.sidebar.menu-min .nav-list .submenu .submenu a .badge,
.sidebar.menu-min .nav-list .submenu .submenu a .label {
  top: 0;
}
.sidebar.menu-min .nav-list > li > a {
  position: relative;
  height: 39px;
  line-height: 37px;
  padding: 0;
}
.sidebar.menu-min .nav-list > li > a > .menu-icon {
  font-size: 18px;
  height: auto;
  line-height: inherit;
  display: inline-block;
  vertical-align: sub;
  margin-right: 0;
  width: 44px;
}
.sidebar.menu-min .nav-list > li > a > .menu-text {
  display: none;
  text-align: left;
  position: absolute;
  top: -1px;
  left: 42px;
  z-index: 1025;
  width: 176px;
  height: 41px;
  line-height: 38px;
  padding-left: 12px;
  border: 1px solid;
}
.sidebar.menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
  top: -1px;
  border-width: 1px 1px 0;
}
.sidebar.menu-min .nav-list > li > a .arrow {
  display: none;
}
.sidebar.menu-min .nav-list > li:hover > a > .menu-text,
.sidebar.menu-min .nav-list > li.hover-show > a > .menu-text {
  display: block;
}
.sidebar.menu-min .nav-list > li.active > a:after {
  border-width: 10px 6px;
  top: 8px;
}
.sidebar.menu-min .nav-list > li.active.open > a:after {
  display: block;
}
.sidebar.menu-min .nav-list > li.active.open li.active > a:after {
  display: none;
}
.sidebar.menu-min .nav-list > li > .submenu {
  position: absolute;
  z-index: 1026;
  left: 42px;
  top: -1px;
  margin-top: 40px;
  padding-bottom: 2px;
  width: 176px;
  display: none !important;
  height: auto !important;
  visibility: visible !important;
}
.sidebar.menu-min .nav-list > li > .submenu:before {
  display: none;
}
.sidebar.menu-min .nav-list > li > .submenu:after {
  content: "";
  display: block;
  height: 1px;
  width: 1px;
  position: absolute;
  right: -1px;
  top: -1px;
  border-right: 1px solid;
  border-right-color: inherit;
}
.sidebar.menu-min .nav-list > li > .submenu li:before {
  display: none;
}
.sidebar.menu-min .nav-list > li > .submenu li > a {
  margin-left: 0;
  padding-left: 22px;
}
.sidebar.menu-min .nav-list > li > .submenu li > a > .menu-icon {
  left: 4px;
}
.sidebar.menu-min .nav-list > li.hover > .submenu {
  padding-bottom: 0;
}
.sidebar.menu-min .nav-list > li.open > .submenu {
  display: none;
}
.sidebar.menu-min .nav-list > li:hover > .submenu,
.sidebar.menu-min .nav-list > li.hover-show > .submenu {
  display: block !important;
}
.sidebar.menu-min .nav-list > li > .arrow {
  top: 10px;
}
.sidebar.menu-min .nav-list > li > .arrow:after,
.sidebar.menu-min .nav-list > li > .arrow:before {
  border-width: 8px;
  left: -16px;
}
.sidebar.menu-min .nav-list > li > .arrow:before {
  left: -17px;
}
.sidebar.menu-min .nav-list li > .arrow {
  right: -1px;
}
.sidebar.menu-min .nav-list > li:hover > .arrow,
.sidebar.menu-min .nav-list > li.hover-show > .arrow {
  display: block;
}
.sidebar.menu-min .sidebar-shortcuts {
  position: relative;
  overflow: visible;
  z-index: 1;
}
.sidebar.menu-min .sidebar-shortcuts-mini {
  display: block;
}
.sidebar.menu-min .sidebar-shortcuts-large {
  display: none;
  position: absolute;
  top: 0;
  left: 42px;
  width: 182px;
  padding: 0 2px 3px;
  border: 1px solid;
  border-top-width: 0;
}
.sidebar.menu-min .sidebar-shortcuts:hover .sidebar-shortcuts-large,
.sidebar.menu-min .sidebar-shortcuts.hover-show .sidebar-shortcuts-large {
  display: block;
}
.sidebar.menu-min .sidebar-toggle:before {
  left: 5px;
  right: 5px;
}
.sidebar.menu-min .sidebar-toggle > .ace-icon {
  font-size: 13px;
  padding: 0 4px;
  line-height: 15px;
  border-width: 1px;
}
.sidebar.menu-min .nav-list > li > .submenu li > .submenu > li > a {
  margin-left: 2px;
  padding-left: 30px;
}
.sidebar.menu-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
  margin-left: 2px;
  padding-left: 45px;
}
.sidebar.menu-min .nav-list > li > .submenu li.active > a:after {
  display: none;
}
.sidebar.menu-min .nav-list li.active.open > .submenu > li.active > a:after {
  display: none;
}
.sidebar.menu-min .nav-list > li.active:hover > a:after,
.sidebar.menu-min .nav-list > li.active.hover-show > a:after,
.sidebar.menu-min .nav-list > li.active.open:hover > a:after {
  display: none;
}
.sidebar.menu-min .nav-list > li.active:hover:after {
  display: none;
}
@media (min-width: 992px) {
  .sidebar.menu-min .nav-list li.hover > .submenu {
    margin-left: 0;
  }
  .sidebar.menu-min .nav-list > li > .submenu li.hover > .submenu > li > a,
  .sidebar.menu-min .nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a {
    margin-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .sidebar.menu-min.compact .nav-list > li > a {
    height: 39px;
    line-height: 37px;
    padding: 0 16px 0 7px;
    position: relative;
  }
  .sidebar.menu-min.compact .nav-list > li > a > .menu-icon {
    font-size: 18px;
    height: auto;
    line-height: inherit;
    display: inline-block;
    vertical-align: sub;
    width: auto;
  }
}
@media only screen and (min-width: 992px) {
  .navbar.h-navbar {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .sidebar.h-sidebar {
    position: relative;
    float: none !important;
    width: auto;
    margin-top: 17px;
    border-width: 0 !important;
    box-shadow: none;
  }
  .sidebar.h-sidebar.menu-min,
  .sidebar.h-sidebar.compact {
    width: auto;
  }
  .sidebar.h-sidebar.sidebar-fixed {
    position: fixed;
  }
  .sidebar.h-sidebar.no-gap {
    margin-top: auto;
  }
  .sidebar.h-sidebar.no-gap .nav-list {
    padding-top: 3px;
  }
  .sidebar.h-sidebar:before {
    display: block;
    position: absolute;
    z-index: -1;
    left: 0 !important;
    right: 0 !important;
    top: -17px;
    bottom: auto;
    height: 17px;
    width: auto;
    border-width: 0;
    border-bottom: 1px solid;
    border-bottom-color: inherit;
  }
  .sidebar.h-sidebar.lower-highlight .nav-list > li:before {
    top: 0;
  }
  .main-content .sidebar.h-sidebar .nav-list > li:before {
    top: 0;
  }
  .sidebar.h-sidebar .nav-list {
    border-width: 0;
    border-bottom: 2px solid #79B0CE;
  }
  .sidebar.h-sidebar .nav-list > li {
    float: left;
    border-width: 0 0 0 1px;
    margin-left: 0;
  }
  .sidebar.h-sidebar .nav-list > li:last-child {
    border-right-width: 1px;
  }
  .sidebar.h-sidebar .nav-list > li:before {
    left: -1px;
    right: -1px;
    bottom: auto;
    top: -2px;
    max-width: none;
    width: auto;
    height: 2px;
    border-width: 0;
  }
  .sidebar.h-sidebar .nav-list > li.active:before {
    display: block;
  }
  .sidebar.h-sidebar .nav-list > li.active:after {
    display: none;
  }
  .sidebar.h-sidebar .nav-list > li:first-child {
    border-left-width: 0;
    margin-left: 0;
  }
  .sidebar.h-sidebar .sidebar-shortcuts + .nav-list > li:first-child {
    border-left-width: 1px;
    margin-left: -1px;
  }
  .sidebar.h-sidebar .nav-list > li > a {
    line-height: 22px;
    height: auto;
    padding: 10px 14px;
  }
  .sidebar.h-sidebar .nav-list > li > a > .menu-icon {
    display: block;
    margin: 1px 0 4px;
    line-height: inherit;
    width: auto;
  }
  .sidebar.h-sidebar .nav-list > li > a > .arrow {
    display: none;
  }
  .sidebar.h-sidebar .nav-list > li.hover.pull_right {
    float: none !important;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .submenu {
    top: 100%;
    left: 1px;
    margin-top: auto;
    margin-left: -2px;
    width: 190px;
    z-index: 1026;
  }
  .sidebar.h-sidebar .nav-list > li.hover.pull_right > .submenu {
    left: auto;
    right: 1px;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .arrow {
    right: auto;
    top: auto;
    left: 24px;
    bottom: 7px;
  }
  .sidebar.h-sidebar .nav-list > li.hover.pull_right > .arrow {
    left: auto;
    right: 24px;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .arrow:before,
  .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
    border-width: 0 8px 8px !important;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
    border-color: transparent;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    left: -10px !important;
    right: auto !important;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .arrow:before {
    border-color: transparent;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    right: auto !important;
    left: -10px !important;
    top: -1px !important;
  }
  .sidebar.h-sidebar .nav-list > li.hover:hover > a ~ .arrow,
  .sidebar.h-sidebar .nav-list > li.hover.hover-show > a ~ .arrow {
    display: none;
  }
  .sidebar.h-sidebar .nav-list > li.hover:hover > a.dropdown-toggle ~ .arrow,
  .sidebar.h-sidebar .nav-list > li.hover.hover-show > a.dropdown-toggle ~ .arrow {
    display: block;
  }
  .sidebar.h-sidebar .nav-list > li.hover-flip > .submenu {
    left: auto;
    right: 1px;
    margin-left: auto;
    margin-right: -2px;
  }
  .sidebar.h-sidebar .nav-list > li.hover-flip > .arrow {
    left: auto;
    right: 24px;
  }
  .sidebar.h-sidebar .nav-list li li.hover-flip > .submenu {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: -2px;
  }
  .sidebar.h-sidebar .nav-list li li.hover-flip > .arrow {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    left: 0;
    right: auto;
  }
  .sidebar.h-sidebar .sidebar-shortcuts-large:before,
  .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    display: block;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    border: 1px solid transparent;
    border-width: 0 8px 8px;
  }
  .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-right-color: transparent;
    top: -8px;
  }
  .sidebar.h-sidebar .sidebar-shortcuts-large:before {
    border-bottom-color: #CCC;
    top: -9px;
  }
  .sidebar.h-sidebar .sidebar-toggle {
    position: absolute;
    top: 12px;
    right: 6px;
    background-color: transparent;
    border-width: 0;
  }
  .sidebar.h-sidebar .sidebar-shortcuts {
    float: left;
    display: block;
    overflow: visible;
    position: relative;
    margin: 0;
    padding: 12px 8px 9px;
    max-height: none !important;
    background-color: transparent;
  }
  .sidebar.h-sidebar .sidebar-shortcuts:hover .sidebar-shortcuts-large,
  .sidebar.h-sidebar .sidebar-shortcuts.hover-show .sidebar-shortcuts-large {
    display: block;
  }
  .sidebar.h-sidebar .sidebar-shortcuts-large {
    display: none;
    width: 52px;
    height: auto;
    position: absolute;
    z-index: 1025;
    top: 100%;
    margin-top: -5px;
    left: 10px;
    padding: 3px 2px;
    border: 1px solid;
  }
  .sidebar.h-sidebar .sidebar-shortcuts-mini {
    width: auto;
    max-width: 52px;
    display: block;
    background-color: transparent;
    padding-top: 3px;
    padding-bottom: 5px;
  }
  .sidebar.h-sidebar .sidebar-shortcuts-mini > .btn {
    padding: 9px;
  }
  .sidebar.h-sidebar .nav-list a .badge,
  .sidebar.h-sidebar .nav-list a .label {
    position: absolute;
    top: 11px;
    right: auto !important;
    left: 50%;
    margin-left: 10px;
  }
  .sidebar.h-sidebar + .main-content {
    margin-left: 0;
  }
  .sidebar.h-sidebar + .main-content .breadcrumbs {
    margin: 6px 9px;
    border: 1px solid;
    border-color: #E5E5E5;
  }
  .h-sidebar.sidebar-fixed {
    top: auto;
    width: 100%;
    left: 0;
    right: 0;
  }
  .h-sidebar.sidebar-fixed:before {
    z-index: auto;
  }
  .h-sidebar.sidebar-fixed,
  .h-sidebar.sidebar-fixed .nav-list {
    border-right-width: 0;
    border-left-width: 0;
  }
  .h-sidebar.sidebar-fixed .sidebar-shortcuts {
    border-width: 0;
  }
  .h-sidebar.sidebar-fixed + .main-content {
    padding-top: 88px;
  }
  .h-sidebar.sidebar-fixed.no-gap + .main-content {
    padding-top: 74px;
  }
  .h-sidebar.sidebar-fixed.menu-min + .main-content {
    padding-top: 63px;
  }
  .h-sidebar.sidebar-fixed.menu-min.no-gap + .main-content {
    padding-top: 49px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 991px) {
  .sidebar.h-sidebar + .main-content .breadcrumbs {
    margin: 0;
    border-width: 0 0 1px;
  }
}
.main-container.container .h-sidebar.sidebar-fixed {
  left: 0;
  right: 0;
  width: auto;
}
@media (min-width: 768px) {
  .main-container.container .h-sidebar.sidebar-fixed {
    left: auto !important;
    right: auto !important;
    width: 744px;
  }
}
@media (min-width: 992px) {
  .main-container.container .h-sidebar.sidebar-fixed {
    width: 964px;
  }
}
@media (min-width: 1200px) {
  .main-container.container .h-sidebar.sidebar-fixed {
    width: 1164px;
  }
}
/**
  @media (min-width: @screen-sm-min) and (max-width: @grid-float-breakpoint-max) {
   .main-container.container .sidebar.sidebar-fixed
   {
    left: auto;// !important;
    right: auto;// !important;
    width: @container-sm;// !important;
   }
  }


  @media (max-width: @grid-float-breakpoint-max) {
    .main-container.container .sidebar.sidebar-fixed {
      .sidebar-shortcuts {
        max-height: none;
        border-bottom-width: 0;
      }
    }
  }
  */
@media (min-width: 992px) {
  .sidebar.h-sidebar.menu-min .nav.nav-list > li > a,
  .sidebar.h-sidebar.menu-min.compact .nav.nav-list > li > a {
    padding: 9px 12px;
  }
  .sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-text {
    left: -1px;
    top: 100%;
    width: 190px;
  }
  .sidebar.h-sidebar.menu-min .nav-list > li > a > .menu-icon {
    margin: 2px 0;
  }
  .sidebar.h-sidebar.menu-min .nav-list > li.hover > .submenu {
    top: 100%;
    margin-top: 41px;
  }
  .sidebar.h-sidebar.menu-min .nav-list > li.hover:hover > a ~ .arrow,
  .sidebar.h-sidebar.menu-min .nav-list > li.hover.hover-show > a ~ .arrow {
    display: block;
  }
  .sidebar.h-sidebar.menu-min .sidebar-toggle {
    top: 6px;
  }
  .sidebar.h-sidebar.menu-min .sidebar-shortcuts {
    padding: 0;
  }
  .sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini {
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .sidebar.h-sidebar.menu-min .sidebar-shortcuts-mini > .btn {
    padding: 8px;
  }
  .sidebar.h-sidebar.menu-min .sidebar-shortcuts-large {
    left: 1px;
    margin-top: 4px;
  }
  .sidebar.h-sidebar.compact .nav-list > li > a > .menu-icon {
    font-size: 18px;
    height: auto;
  }
  .sidebar.h-sidebar.compact.menu-min .nav-list > li > a {
    height: auto;
    line-height: 22px;
  }
}
/**
@media only screen and (max-width: @grid-float-breakpoint-max) {
  .menu-toggler + .sidebar.h-sidebar {
    margin-top: (@breadcrumb-height - 1) !important;
  }
  .sidebar.h-sidebar.responsive-min , .sidebar.h-sidebar.navbar-collapse {
    margin-top: auto !important;
  }
}
*/
.main-container .menu-toggler {
  display: none;
}
@media only screen and (max-width: 991px) {
  .sidebar.responsive:before {
    display: none;
  }
  .sidebar.responsive {
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.15);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.15);
    z-index: 1027;
    overflow: hidden;
    max-height: 0;
    -moz-transform: translateX(-200px);
    -webkit-transform: translateX(-200px);
    -o-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    transform: translateX(-200px);
    -webkit-transition: -webkit-transform 0.2s linear 0s, max-height 0s linear 0.2s;
    -moz-transition: -moz-transform 0.2s linear 0s, max-height 0s linear 0.2s;
    -o-transition: -o-transform 0.2s linear 0s, max-height 0s linear 0.2s;
    transition: transform 0.2s linear 0s, max-height 0s linear 0.2s;
    position: relative;
    bottom: auto;
    left: 190px;
    margin-left: -190px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .sidebar.responsive.compact {
    -moz-transform: translateX(-115px);
    -webkit-transform: translateX(-115px);
    -o-transform: translateX(-115px);
    -ms-transform: translateX(-115px);
    transform: translateX(-115px);
  }
  .sidebar.responsive.compact.push_away.display + .main-content {
    -moz-transform: translateX(105px);
    -webkit-transform: translateX(105px);
    -o-transform: translateX(105px);
    -ms-transform: translateX(105px);
    transform: translateX(105px);
  }
  .navbar.navbar-fixed-top + .main-container .sidebar.responsive.compact.push_away.display ~ .footer .footer-inner {
    -moz-transform: translateX(105px);
    -webkit-transform: translateX(105px);
    -o-transform: translateX(105px);
    -ms-transform: translateX(105px);
    transform: translateX(105px);
  }
}
@media only screen and (max-width: 991px) {
  .sidebar.responsive.menu-min {
    -moz-transform: translateX(-53px);
    -webkit-transform: translateX(-53px);
    -o-transform: translateX(-53px);
    -ms-transform: translateX(-53px);
    transform: translateX(-53px);
  }
  .sidebar.responsive.display {
    -moz-transform: none !important;
    -webkit-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    overflow: visible;
    max-height: 2000px;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
  }
  .sidebar.responsive.push_away {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .sidebar.responsive.push_away:before {
    display: block !important;
  }
  .sidebar.responsive.push_away + .main-content {
    -webkit-transition: -webkit-transform 0.2s linear 0s;
    -moz-transition: -moz-transform 0.2s linear 0s;
    -o-transition: -o-transform 0.2s linear 0s;
    transition: transform 0.2s linear 0s;
  }
  .sidebar.responsive.push_away.display + .main-content {
    -moz-transform: translateX(190px);
    -webkit-transform: translateX(190px);
    -o-transform: translateX(190px);
    -ms-transform: translateX(190px);
    transform: translateX(190px);
  }
  .sidebar.responsive.push_away.display.menu-min + .main-content {
    -moz-transform: translateX(43px);
    -webkit-transform: translateX(43px);
    -o-transform: translateX(43px);
    -ms-transform: translateX(43px);
    transform: translateX(43px);
  }
  .navbar.navbar-fixed-top + .main-container .sidebar.responsive.push_away:before {
    height: 5000px;
  }
  .navbar.navbar-fixed-top + .main-container .sidebar.responsive.push_away ~ .footer .footer-inner {
    -webkit-transition: -webkit-transform 0.2s linear 0s;
    -moz-transition: -moz-transform 0.2s linear 0s;
    -o-transition: -o-transform 0.2s linear 0s;
    transition: transform 0.2s linear 0s;
  }
  .navbar.navbar-fixed-top + .main-container .sidebar.responsive.push_away.display ~ .footer .footer-inner {
    -moz-transform: translateX(190px);
    -webkit-transform: translateX(190px);
    -o-transform: translateX(190px);
    -ms-transform: translateX(190px);
    transform: translateX(190px);
  }
  .navbar.navbar-fixed-top + .main-container .sidebar.responsive.push_away.display.menu-min ~ .footer .footer-inner {
    -moz-transform: translateX(43px);
    -webkit-transform: translateX(43px);
    -o-transform: translateX(43px);
    -ms-transform: translateX(43px);
    transform: translateX(43px);
  }
  .sidebar.responsive + .main-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-container.container .sidebar.responsive {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    display: none;
  }
  .main-container.container .sidebar.responsive:before {
    display: none !important;
  }
  .main-container.container .sidebar.responsive.display {
    display: block;
  }
  .main-container.container .sidebar.responsive.push_away + .main-content,
  .main-container.container .sidebar.responsive.push_away ~ .footer .footer-inner {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    -moz-transform: none !important;
    -webkit-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .responsive.sidebar-fixed {
    position: fixed;
  }
  .main-container .menu-toggler.fixed {
    position: fixed;
    left: auto;
    z-index: 1026;
    -moz-backface-visibility: hidden;
  }
}
@media only screen and (max-width: 991px) {
  .menu-toggler + .sidebar.responsive {
    margin-top: 40px;
  }
  .main-container .menu-toggler {
    display: block;
    position: absolute;
    z-index: 1026;
    width: 52px;
    height: 32px;
    margin-right: 2px;
    line-height: normal;
    padding-left: 33px;
    padding-top: 7px;
    padding-bottom: 1px;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .main-container .menu-toggler:hover {
    text-decoration: none;
  }
  .main-container .menu-toggler:focus {
    outline: none;
  }
  .main-container .menu-toggler:before {
    border-top: 1px solid #87B87F;
    border-bottom: 1px solid #6FB3E0;
    height: 2px;
    width: 24px;
    content: "";
    position: absolute;
    z-index: 1;
    top: 13px;
    left: 4px;
    -webkit-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    -o-transition: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .main-container .menu-toggler:after {
    border-top: 1px solid #FFA24D;
    border-bottom: 1px solid #D15B47;
    content: "";
    height: 2px;
    width: 24px;
    position: absolute;
    top: 19px;
    left: 4px;
    -webkit-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    -o-transition: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .main-container .menu-toggler.display:before {
    height: 4px;
    top: 8px;
    border-width: 2px;
  }
  .main-container .menu-toggler.display:after {
    height: 4px;
    top: 20px;
    border-width: 2px;
  }
  .main-container .menu-toggler > .toggler-text {
    display: block;
    position: absolute;
    bottom: -9px;
    left: 0;
    border: 1px solid transparent;
    border-width: 9px 42px 0;
    border-top-color: #444;
  }
  .main-container .menu-toggler > .toggler-text:after {
    display: block;
    content: "MENU";
    color: #FFF;
    position: absolute;
    left: -8px;
    top: -41px;
  }
  .menu-toggler + .responsive.sidebar-scroll .nav-wrap {
    overflow: hidden;
  }
}
@media only screen and (max-width: 320px) {
  .main-container .menu-toggler {
    width: 0;
  }
  .main-container .menu-toggler > .toggler-text {
    border-width: 7px 16px;
    bottom: -14px;
  }
  .main-container .menu-toggler > .toggler-text:after {
    font-size: 9px;
    font-weight: normal;
    color: #FFF;
    position: absolute;
    left: -13px;
    top: -42px;
  }
  .main-container .menu-toggler:before,
  .main-container .menu-toggler:after {
    margin-top: 8px;
  }
  .main-container .menu-toggler.display:before,
  .main-container .menu-toggler.display:after {
    height: 2px;
    border-width: 1px;
  }
  .main-container .menu-toggler.display:before {
    top: 13px;
  }
  .main-container .menu-toggler.display:after {
    top: 19px;
  }
}
.sidebar-toggle.sidebar-expand {
  display: none;
}
@media (max-width: 991px) {
  .menu-toggler.invisible {
    position: fixed;
    z-index: -999;
    visibility: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
  }
  .sidebar.responsive-min {
    /**
  .nav-list > li.pull_up > .arrow {
   z-index: @zindex-submenu + 1;
   &:after , &:before {
    border-width: 10px;
    left: -20px;
   }
   &:before {
    left: -21px;
  }
  }
  */
  }
  .sidebar.responsive-min,
  .sidebar.responsive-min.compact,
  .sidebar.responsive-min.navbar-collapse {
    width: 43px;
  }
  .sidebar.responsive-min + .main-content {
    margin-left: 43px;
  }
  .sidebar.responsive-min .nav-list a .badge,
  .sidebar.responsive-min .nav-list a .label {
    position: relative;
    top: -1px;
    right: auto;
    left: 4px;
  }
  .sidebar.responsive-min .nav-list .submenu .submenu a .badge,
  .sidebar.responsive-min .nav-list .submenu .submenu a .label {
    top: 0;
  }
  .sidebar.responsive-min .nav-list > li > a {
    position: relative;
    height: 39px;
    line-height: 37px;
    padding: 0;
  }
  .sidebar.responsive-min .nav-list > li > a > .menu-icon {
    font-size: 18px;
    height: auto;
    line-height: inherit;
    display: inline-block;
    vertical-align: sub;
    margin-right: 0;
    width: 44px;
  }
  .sidebar.responsive-min .nav-list > li > a > .menu-text {
    display: none;
    text-align: left;
    position: absolute;
    top: -1px;
    left: 42px;
    z-index: 1025;
    width: 176px;
    height: 41px;
    line-height: 38px;
    padding-left: 12px;
    border: 1px solid;
  }
  .sidebar.responsive-min .nav-list > li > a.dropdown-toggle > .menu-text {
    top: -1px;
    border-width: 1px 1px 0;
  }
  .sidebar.responsive-min .nav-list > li > a .arrow {
    display: none;
  }
  .sidebar.responsive-min .nav-list > li:hover > a > .menu-text,
  .sidebar.responsive-min .nav-list > li.hover-show > a > .menu-text {
    display: block;
  }
  .sidebar.responsive-min .nav-list > li.active > a:after {
    border-width: 10px 6px;
    top: 8px;
  }
  .sidebar.responsive-min .nav-list > li.active.open > a:after {
    display: block;
  }
  .sidebar.responsive-min .nav-list > li.active.open li.active > a:after {
    display: none;
  }
  .sidebar.responsive-min .nav-list > li > .submenu {
    position: absolute;
    z-index: 1026;
    left: 42px;
    top: -1px;
    margin-top: 40px;
    padding-bottom: 2px;
    width: 176px;
    display: none !important;
    height: auto !important;
    visibility: visible !important;
  }
  .sidebar.responsive-min .nav-list > li > .submenu:before {
    display: none;
  }
  .sidebar.responsive-min .nav-list > li > .submenu:after {
    content: "";
    display: block;
    height: 1px;
    width: 1px;
    position: absolute;
    right: -1px;
    top: -1px;
    border-right: 1px solid;
    border-right-color: inherit;
  }
  .sidebar.responsive-min .nav-list > li > .submenu li:before {
    display: none;
  }
  .sidebar.responsive-min .nav-list > li > .submenu li > a {
    margin-left: 0;
    padding-left: 22px;
  }
  .sidebar.responsive-min .nav-list > li > .submenu li > a > .menu-icon {
    left: 4px;
  }
  .sidebar.responsive-min .nav-list > li.hover > .submenu {
    padding-bottom: 0;
  }
  .sidebar.responsive-min .nav-list > li.open > .submenu {
    display: none;
  }
  .sidebar.responsive-min .nav-list > li:hover > .submenu,
  .sidebar.responsive-min .nav-list > li.hover-show > .submenu {
    display: block !important;
  }
  .sidebar.responsive-min .nav-list > li > .arrow {
    top: 10px;
  }
  .sidebar.responsive-min .nav-list > li > .arrow:after,
  .sidebar.responsive-min .nav-list > li > .arrow:before {
    border-width: 8px;
    left: -16px;
  }
  .sidebar.responsive-min .nav-list > li > .arrow:before {
    left: -17px;
  }
  .sidebar.responsive-min .nav-list li > .arrow {
    right: -1px;
  }
  .sidebar.responsive-min .nav-list > li:hover > .arrow,
  .sidebar.responsive-min .nav-list > li.hover-show > .arrow {
    display: block;
  }
  .sidebar.responsive-min .sidebar-shortcuts {
    position: relative;
    overflow: visible;
    z-index: 1;
  }
  .sidebar.responsive-min .sidebar-shortcuts-mini {
    display: block;
  }
  .sidebar.responsive-min .sidebar-shortcuts-large {
    display: none;
    position: absolute;
    top: 0;
    left: 42px;
    width: 182px;
    padding: 0 2px 3px;
    border: 1px solid;
    border-top-width: 0;
  }
  .sidebar.responsive-min .sidebar-shortcuts:hover .sidebar-shortcuts-large,
  .sidebar.responsive-min .sidebar-shortcuts.hover-show .sidebar-shortcuts-large {
    display: block;
  }
  .sidebar.responsive-min .sidebar-toggle:before {
    left: 5px;
    right: 5px;
  }
  .sidebar.responsive-min .sidebar-toggle > .ace-icon {
    font-size: 13px;
    padding: 0 4px;
    line-height: 15px;
    border-width: 1px;
  }
  .sidebar.responsive-min .nav-list > li > .submenu li > .submenu > li > a {
    margin-left: 2px;
    padding-left: 30px;
  }
  .sidebar.responsive-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
    margin-left: 2px;
    padding-left: 45px;
  }
  .sidebar.responsive-min .nav-list > li > .submenu li.active > a:after {
    display: none;
  }
  .sidebar.responsive-min .nav-list li.active.open > .submenu > li.active > a:after {
    display: none;
  }
  .sidebar.responsive-min .nav-list > li.active:hover > a:after,
  .sidebar.responsive-min .nav-list > li.active.hover-show > a:after,
  .sidebar.responsive-min .nav-list > li.active.open:hover > a:after {
    display: none;
  }
  .sidebar.responsive-min .nav-list > li.active:hover:after {
    display: none;
  }
  .sidebar.responsive-min:before {
    display: block;
  }
  .sidebar.responsive-min .sidebar-toggle.sidebar-collapse {
    display: none;
  }
  .sidebar.responsive-min .sidebar-toggle.sidebar-expand {
    display: block;
  }
  .sidebar.responsive-min + .main-content {
    margin-left: 43px !important;
  }
  .rtl .sidebar.responsive-min + .main-content {
    margin-left: auto !important;
    margin-right: 43px !important;
  }
  .sidebar.responsive-min + .main-content .breadcrumb {
    margin-left: 12px;
  }
  .sidebar.responsive-max {
    display: block;
    position: relative;
    left: 190px;
    margin-left: -190px;
    z-index: 1027;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.15);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.15);
  }
  .sidebar.responsive-max:before {
    display: none;
  }
  .sidebar.responsive-max .sidebar-toggle.sidebar-collapse {
    display: none;
  }
  .sidebar.responsive-max .sidebar-toggle.sidebar-expand {
    display: block;
    z-index: 1;
  }
  .sidebar.responsive-max + .main-content {
    margin-left: 43px !important;
  }
  .sidebar.responsive-max + .main-content .breadcrumb {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar.navbar-collapse {
    max-height: none;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar.navbar-collapse {
    position: relative;
    float: none !important;
    margin-top: auto !important;
    z-index: 1;
    width: 100% !important;
    max-height: 340px;
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar.navbar-collapse:before {
    display: none;
  }
  .sidebar.navbar-collapse .nav-list > li:last-child {
    border-bottom-width: 0;
  }
  .sidebar.navbar-collapse .nav-list > li > a {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 20px;
    height: 41px;
  }
  .sidebar.navbar-collapse .nav-list > li:before {
    height: 43px;
  }
  .sidebar.navbar-collapse .nav-list > li.active:after {
    display: none;
  }
  .sidebar.navbar-collapse li > .arrow {
    display: none !important;
  }
  .sidebar.navbar-collapse .nav-list li > .submenu {
    border-left-width: 0;
  }
  .sidebar.navbar-collapse .sidebar-toggle {
    display: none;
  }
  .sidebar.navbar-collapse .sidebar-shortcuts {
    padding: 3px 0 6px;
    max-height: none;
    border-bottom-width: 0;
  }
  .sidebar.navbar-collapse + .main-content {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .sidebar.navbar-collapse + .main-content .breadcrumb {
    margin-left: 12px;
  }
  .sidebar.navbar-collapse .nav-wrap + .ace-scroll {
    display: none;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu {
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    z-index: auto;
    margin-top: auto;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 1px 0 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu:before {
    display: block;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu > li:before {
    display: block;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > a > .menu-icon {
    left: 10px;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu:after {
    display: none;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > a {
    padding: 7px 0 9px 37px;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > .submenu > li > a {
    margin-left: 20px;
    padding-left: 22px;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
    margin-left: 20px;
    padding-left: 38px;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > a:hover:before {
    width: 3px;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > a {
    text-align: left;
    padding-left: 7px;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > a > .menu-icon {
    display: inline-block;
    width: auto;
    margin-right: 2px;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > a > .menu-text {
    position: relative;
    display: inline;
    line-height: normal;
    padding-left: 0;
    height: auto;
    top: auto;
    left: auto;
    z-index: auto;
    width: auto;
    border-width: 0 !important;
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .sidebar.navbar-collapse.menu-min .nav-list a > .arrow {
    display: block;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li:hover > .submenu {
    display: none !important;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li.active > .submenu {
    display: block !important;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu.nav-show {
    display: block !important;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu.nav-hide {
    display: none !important;
  }
  .sidebar.navbar-collapse.menu-min .sidebar-shortcuts-large {
    display: block;
    position: static;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-width: 0 !important;
    background-color: transparent !important;
    width: auto;
    padding: 0;
  }
  .sidebar.navbar-collapse.menu-min .sidebar-shortcuts-mini {
    display: none;
  }
  .sidebar.navbar-collapse .nav-list a .badge,
  .sidebar.navbar-collapse .nav-list a .label {
    position: relative;
    top: -1px;
    right: auto;
    left: 4px;
  }
  .sidebar.navbar-collapse .nav-list > li.active:after,
  .sidebar.navbar-collapse .nav-list li.active > a:before,
  .sidebar.navbar-collapse .nav-list li.active > a:after {
    display: none !important;
  }
  .sidebar.navbar-collapse .nav-list > li.highlight.active > a:before,
  .sidebar.navbar-collapse .nav-list > li.highlight.active > a:after {
    display: none !important;
  }
}
@media (max-width: 991px) and (max-height: 400px) {
  .sidebar.navbar-collapse {
    max-height: 300px;
  }
}
@media (max-width: 991px) and (max-height: 350px) {
  .sidebar.navbar-collapse {
    max-height: 250px;
  }
}
@media (max-width: 991px) and (max-height: 300px) {
  .sidebar.navbar-collapse {
    max-height: 200px;
  }
}
@media (max-width: 991px) and (max-height: 250px) {
  .sidebar.navbar-collapse {
    max-height: 150px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .sidebar.navbar-collapse .nav-list > li > .submenu li.hover > .submenu > li > a,
  .sidebar.navbar-collapse .nav-list > li > .submenu li > .submenu > li.hover > .submenu > li > a {
    margin-left: 20px !important;
  }
}
@media (max-width: 991px) {
  .navbar-fixed-top + .main-container .sidebar.navbar-collapse {
    position: fixed;
    z-index: 1027;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-fixed-top + .main-container.container .sidebar.navbar-collapse {
    width: 744px !important;
  }
}
.main-content-inner {
  float: left;
  width: 100%;
}
.main-content .sidebar:before {
  top: 150px;
}
@media only screen and (min-width: 992px) {
  body.mob-safari .sidebar.h-sidebar + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
    top: 131px;
  }
  body.mob-safari .sidebar.h-sidebar.no-gap + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
    top: 114px;
  }
  body.mob-safari .sidebar.h-sidebar.menu-min + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
    top: 106px;
  }
  body.mob-safari .sidebar.h-sidebar.menu-min.no-gap + .main-content .sidebar.sidebar-fixed:not(.h-sidebar) {
    top: 89px;
  }
}
@media only screen and (min-width: 992px) {
  .h-navbar.navbar-fixed-top + .main-container .sidebar:not(.h-sidebar):before {
    top: 150px;
  }
  .h-navbar.navbar-fixed-top + .main-container .sidebar:not(.h-sidebar) .nav-list {
    padding-top: 3px;
  }
  .h-navbar.navbar-fixed-top + .main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts ~ .nav-list,
  .h-navbar.navbar-fixed-top + .main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts ~ .nav-wrap-up .nav-list {
    padding-top: 0;
  }
  .h-navbar.navbar-fixed-top + .main-container .sidebar:not(.h-sidebar) .sidebar-shortcuts {
    padding-top: 3px;
    min-height: 43px;
  }
  .h-navbar.navbar-fixed-top + .main-container .sidebar:not(.h-sidebar).menu-min .sidebar-shortcuts-large {
    line-height: 40px;
  }
  .h-navbar.navbar-fixed-top + .main-container .main-content .sidebar:not(.h-sidebar) .nav-list {
    padding-top: 0;
  }
  .h-navbar.navbar-fixed-top + .main-container .main-content .sidebar.sidebar-fixed {
    z-index: 1026;
  }
  .main-content .h-sidebar {
    padding-top: 1px;
    margin-top: 0;
  }
  .main-content .h-sidebar:before {
    display: none;
  }
  .main-content .h-sidebar + .page-content {
    margin-left: auto;
  }
  .main-content .h-sidebar.sidebar-fixed {
    left: 190px;
  }
  .main-content .h-sidebar.sidebar-fixed + .page-content {
    margin-top: 74px;
  }
  .main-content .h-sidebar.sidebar-fixed.menu-min + .page-content {
    margin-top: 49px;
  }
  .main-content .h-sidebar.menu-min .nav-list > li > a {
    padding: 8px 12px 7px;
  }
  .sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
    left: 105px;
  }
  .sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
    left: 43px;
  }
  .navbar.navbar-fixed-top + .main-container .main-content .h-sidebar.sidebar-fixed {
    padding-top: 3px;
  }
}
.container.main-container .sidebar + .main-content .h-sidebar.sidebar-fixed {
  left: auto;
  right: auto;
}
@media (min-width: 992px) {
  .container.main-container .sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
    width: 639px;
  }
}
@media (min-width: 992px) {
  .container.main-container .main-content .h-sidebar.sidebar-fixed {
    width: 744px;
  }
  .container.main-container .sidebar + .main-content .h-sidebar.sidebar-fixed {
    width: 554px;
  }
  .container.main-container .sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
    width: 701px;
  }
}
@media (min-width: 992px) {
  .container.main-container .sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
    width: 859px;
  }
}
@media (min-width: 992px) {
  .container.main-container .main-content .h-sidebar.sidebar-fixed {
    width: 964px;
  }
  .container.main-container .sidebar + .main-content .h-sidebar.sidebar-fixed {
    width: 774px;
  }
  .container.main-container .sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
    width: 921px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .sidebar.compact + .main-content .h-sidebar.sidebar-fixed {
    width: 1059px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .main-content .h-sidebar.sidebar-fixed {
    width: 1164px;
  }
  .container.main-container .sidebar + .main-content .h-sidebar.sidebar-fixed {
    width: 974px;
  }
  .container.main-container .sidebar.menu-min + .main-content .h-sidebar.sidebar-fixed {
    width: 1121px;
  }
}
@media only screen and (max-width: 991px) {
  .main-container .main-content .sidebar.sidebar-fixed {
    z-index: 1026;
  }
  .navbar-fixed-top + .main-container .main-content .sidebar {
    position: fixed;
    z-index: 1026;
  }
}
.sidebar-scroll .sidebar-shortcuts {
  min-height: 41px;
  border-bottom: 1px solid;
}
.sidebar-scroll .sidebar-toggle {
  border-top: 1px solid;
}
.sidebar-scroll .nav-list > li:first-child {
  border-top-width: 0;
}
.sidebar-scroll .nav-list > li:last-child {
  border-bottom-width: 0;
}
.sidebar-scroll .nav-wrap .sidebar-shortcuts {
  min-height: 40px;
  border-bottom-width: 0;
}
.sidebar-scroll .nav-wrap .sidebar-shortcuts + .nav-list > li:first-child,
.sidebar-scroll .nav-wrap .sidebar-shortcuts + .nav-wrap-up .nav-list > li:first-child {
  border-top-width: 1px;
}
.sidebar-scroll .nav-wrap-t .sidebar-toggle {
  border-top-width: 0;
}
.sidebar-scroll .nav-wrap-t .nav-list > li:last-child {
  border-bottom-width: 1px;
}
.sidebar .nav-wrap + .ace-scroll {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.sidebar .nav-wrap + .scrollout {
  right: -8px;
}
.sidebar .submenu.sub-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 991px) {
  .menu-toggler + .sidebar.responsive + .main-content .breadcrumb {
    margin-left: 90px;
  }
}
@media only screen and (max-width: 320px) {
  .breadcrumb {
    margin-left: 8px;
  }
  .menu-toggler + .sidebar.responsive + .main-content .breadcrumb {
    margin-left: 36px;
  }
}
@media (min-width: 992px) {
  .breadcrumbs-fixed {
    position: fixed;
    right: 0;
    left: 0;
    top: auto;
    z-index: 1024;
  }
  .breadcrumbs-fixed + .page-content {
    padding-top: 49px;
  }
  .sidebar + .main-content .breadcrumbs-fixed {
    left: 190px;
  }
  body.mob-safari {
    /**
   //not needed because breadcrumbs is not fixed at this point
   media (max-width: @screen-topbar-down) {
    .navbar-fixed-top:not(.navbar-collapse) + .main-container .breadcrumbs-fixed {
    top: (@navbar-min-height * 2);
    }
   }*/
  }
  body.mob-safari .breadcrumbs-fixed {
    top: 45px;
  }
}
@media (min-width: 992px) {
  .sidebar.compact + .main-content .breadcrumbs-fixed {
    left: 105px;
  }
}
@media (min-width: 992px) {
  .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    left: 43px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .breadcrumbs-fixed,
  .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    left: 0;
  }
  .sidebar.responsive-min + .main-content .breadcrumbs-fixed,
  .sidebar.responsive-max + .main-content .breadcrumbs-fixed {
    left: 43px;
  }
}
@media (min-width: 992px) {
  .container.main-container .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 744px;
  }
  .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 554px;
  }
  .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 639px;
  }
  .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 701px;
  }
}
@media (min-width: 992px) {
  .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 859px;
  }
}
@media (min-width: 992px) {
  .container.main-container .breadcrumbs-fixed {
    width: 964px;
  }
  .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
    width: 774px;
  }
  .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 921px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 1059px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .breadcrumbs-fixed {
    width: 1164px;
  }
  .container.main-container .sidebar + .main-content .breadcrumbs-fixed {
    width: 974px;
  }
  .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 1121px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .container.main-container .sidebar.compact + .main-content .breadcrumbs-fixed {
    width: 744px;
  }
}
@media (min-width: 992px) and (max-width: 991px) {
  .container.main-container .breadcrumbs-fixed,
  .container.main-container .sidebar.menu-min + .main-content .breadcrumbs-fixed {
    width: 744px;
  }
  .container.main-container .sidebar.responsive-min + .main-content .breadcrumbs-fixed,
  .container.main-container .sidebar.responsive-max + .main-content .breadcrumbs-fixed {
    left: auto;
    right: auto;
    width: 701px;
  }
}
/**
@media (max-width: @grid-float-breakpoint-max) {
 .breadcrumbs-fixed {
  position: relative;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  width: auto !important;

  + .page-content {
    padding-top: @page-content-padding-top;
  }
 }
}
*/
@media (min-width: 992px) {
  .h-sidebar + .main-content .breadcrumbs.breadcrumbs-fixed {
    position: relative;
    top: auto;
    left: auto;
    z-index: auto;
    width: auto;
  }
  .h-sidebar + .main-content .page-content {
    padding-top: 12px;
  }
}
.nav-search {
  position: absolute;
  right: 22px;
  line-height: 24px;
}
.breadcrumbs .nav-search {
  top: 6px;
}
.nav-search .form-search {
  margin-bottom: 0;
}
.nav-search .nav-search-input {
  border: 1px solid #6FB3E0;
  width: 152px;
  height: 28px !important;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px !important;
  font-size: 13px;
  line-height: 1.3;
  color: #666 !important;
  z-index: 11;
  -webkit-transition: width ease .15s;
  -o-transition: width ease .15s;
  transition: width ease .15s;
}
.nav-search .nav-search-input + .dropdown-menu {
  min-width: 0;
  left: 0;
  right: 0;
}
.nav-search .nav-search-input:focus,
.nav-search .nav-search-input:hover {
  border-color: #6FB3E0;
}
.nav-search .nav-search-icon {
  color: #6FB3E0 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  background-color: transparent;
}
.nav-search.minimized .nav-search-input {
  width: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  max-width: 0;
}
.nav-search.minimized:hover .nav-search-input,
.nav-search.minimized .nav-search-btn:active + .nav-search-input,
.nav-search.minimized .nav-search-input:focus,
.nav-search.minimized .nav-search-input:hover,
.nav-search.minimized .nav-search-input:active {
  opacity: 1;
  filter: alpha(opacity=100);
  width: 152px;
  max-width: 152px;
}
.nav-search.minimized .nav-search-icon {
  border: 1px solid;
  border-radius: 100%;
  background-color: #FFF;
  padding: 0 5px !important;
}
.nav-search.minimized:hover .nav-search-icon,
.nav-search.minimized .nav-search-input:focus ~ .nav-search-icon,
.nav-search.minimized .nav-search-input:hover ~ .nav-search-icon,
.nav-search.minimized .nav-search-input:active ~ .nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
.nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
.sidebar > .nav-search {
  position: static;
  background-color: #FAFAFA;
  border-bottom: 1px solid #DDD;
  text-align: center;
  height: 35px;
  padding-top: 6px;
}
.sidebar > .nav-search .nav-search-input {
  width: 162px !important;
  border-radius: 0 !important;
  max-width: 162px !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.sidebar > .nav-search .nav-search-input + .dropdown-menu {
  text-align: left;
}
.sidebar.menu-min .nav-search .form-search {
  position: absolute;
  left: 5px;
  z-index: 14;
}
.sidebar.menu-min .nav-search .nav-search-input {
  width: 0 !important;
  max-width: 0 !important;
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
}
.sidebar.menu-min .nav-search .nav-search-input:hover,
.sidebar.menu-min .nav-search .nav-search-input:focus,
.sidebar.menu-min .nav-search .nav-search-input:active {
  width: 162px !important;
  max-width: 162px !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.sidebar.menu-min .nav-search .nav-search-input:hover ~ #nav-search-icon,
.sidebar.menu-min .nav-search .nav-search-input:focus ~ #nav-search-icon,
.sidebar.menu-min .nav-search .nav-search-input:active ~ #nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
.sidebar.menu-min .nav-search:hover .nav-search-input {
  width: 162px !important;
  max-width: 162px !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.sidebar.menu-min .nav-search:hover .nav-search-input ~ .nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
.sidebar.menu-min .nav-search .nav-search-icon {
  /* inside minimized sidebar */
  border: 1px solid;
  border-radius: 32px;
  background-color: #FFF;
  padding: 0 5px !important;
}
@media (max-width: 991px) {
  .sidebar.responsive-min .nav-search .form-search {
    position: absolute;
    left: 5px;
    z-index: 14;
  }
  .sidebar.responsive-min .nav-search .nav-search-input {
    width: 0 !important;
    max-width: 0 !important;
    opacity: 0 !important;
    filter: alpha(opacity=0) !important;
  }
  .sidebar.responsive-min .nav-search .nav-search-input:hover,
  .sidebar.responsive-min .nav-search .nav-search-input:focus,
  .sidebar.responsive-min .nav-search .nav-search-input:active {
    width: 162px !important;
    max-width: 162px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
  .sidebar.responsive-min .nav-search .nav-search-input:hover ~ #nav-search-icon,
  .sidebar.responsive-min .nav-search .nav-search-input:focus ~ #nav-search-icon,
  .sidebar.responsive-min .nav-search .nav-search-input:active ~ #nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
  .sidebar.responsive-min .nav-search:hover .nav-search-input {
    width: 162px !important;
    max-width: 162px !important;
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }
  .sidebar.responsive-min .nav-search:hover .nav-search-input ~ .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
  .sidebar.responsive-min .nav-search .nav-search-icon {
    /* inside minimized sidebar */
    border: 1px solid;
    border-radius: 32px;
    background-color: #FFF;
    padding: 0 5px !important;
  }
}
@media only screen and (max-width: 991px) {
  .nav-search {
    right: 10px;
  }
  .nav-search .nav-search-input {
    width: 105px;
  }
  .nav-search:hover .nav-search-input,
  .nav-search .nav-search-btn:active + .nav-search-input,
  .nav-search .nav-search-input:focus,
  .nav-search .nav-search-input:hover,
  .nav-search .nav-search-input:active {
    width: 145px;
  }
}
@media only screen and (max-width: 767px) {
  .nav-search .nav-search-input {
    width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    max-width: 0;
  }
  .nav-search:hover .nav-search-input,
  .nav-search .nav-search-btn:active + .nav-search-input,
  .nav-search .nav-search-input:focus,
  .nav-search .nav-search-input:hover,
  .nav-search .nav-search-input:active {
    opacity: 1;
    filter: alpha(opacity=100);
    width: 152px;
    max-width: 152px;
  }
  .nav-search .nav-search-icon {
    border: 1px solid;
    border-radius: 100%;
    background-color: #FFF;
    padding: 0 5px !important;
  }
  .nav-search:hover .nav-search-icon,
  .nav-search .nav-search-input:focus ~ .nav-search-icon,
  .nav-search .nav-search-input:hover ~ .nav-search-icon,
  .nav-search .nav-search-input:active ~ .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
}
.footer {
  padding-top: 75px;
  height: 0;
  width: 0;
}
.footer .footer-inner {
  text-align: center;
  position: absolute;
  z-index: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.sidebar ~ .footer .footer-inner {
  left: 190px;
}
@media (min-width: 992px) {
  .sidebar.compact ~ .footer .footer-inner {
    left: 105px;
  }
}
.sidebar.menu-min ~ .footer .footer-inner {
  left: 43px;
}
@media (min-width: 992px) {
  .sidebar.h-sidebar ~ .footer .footer-inner {
    left: 0;
  }
}
@media (max-width: 991px) {
  .footer .footer-inner,
  .sidebar ~ .footer .footer-inner,
  .sidebar.compact ~ .footer .footer-inner,
  .sidebar.menu-min ~ .footer .footer-inner {
    left: 0;
  }
  .sidebar.responsive-min ~ .footer .footer-inner {
    left: 43px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container.main-container .footer .footer-inner {
    left: auto !important;
    right: auto !important;
    margin-left: auto;
    width: 744px;
  }
}
@media (min-width: 992px) {
  .container.main-container .footer .footer-inner {
    left: auto !important;
    right: auto !important;
    width: 964px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .footer .footer-inner {
    width: 1164px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container.main-container .sidebar ~ .footer .footer-inner {
    left: auto !important;
    right: auto !important;
    margin-left: auto;
    width: 744px;
  }
}
@media (min-width: 992px) {
  .container.main-container .sidebar ~ .footer .footer-inner {
    left: auto !important;
    right: auto !important;
    margin-left: 190px;
    width: 774px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .sidebar ~ .footer .footer-inner {
    margin-left: 190px;
    width: 974px;
  }
}
@media (min-width: 992px) {
  .container.main-container .sidebar.compact ~ .footer .footer-inner {
    margin-left: 105px;
    width: 859px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .sidebar.compact ~ .footer .footer-inner {
    margin-left: 105px;
    width: 1059px;
  }
}
@media (min-width: 992px) {
  .container.main-container .sidebar.menu-min ~ .footer .footer-inner {
    margin-left: 43px;
    width: 921px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .sidebar.menu-min ~ .footer .footer-inner {
    margin-left: 43px;
    width: 1121px;
  }
}
@media (min-width: 992px) {
  .container.main-container .sidebar.h-sidebar ~ .footer .footer-inner {
    margin-left: 0;
    width: 964px;
  }
}
@media (min-width: 992px) {
  .container.main-container .sidebar.h-sidebar ~ .footer .footer-inner {
    margin-left: 0;
    width: 964px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .sidebar.h-sidebar ~ .footer .footer-inner {
    margin-left: 0;
    width: 1164px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .container.main-container .sidebar.responsive-min ~ .footer .footer-inner {
    margin-left: 43px;
    width: 701px;
  }
}
.sidebar {
  background-color: #F2F2F2;
  border-style: solid;
  border-color: #CCC;
  border-width: 0 1px 0 0;
}
.nav-list .open > a,
.nav-list .open > a:hover,
.nav-list .open > a:focus {
  background-color: #FAFAFA;
}
.nav-list > li {
  border-color: #E5E5E5;
}
.nav-list > li > a {
  background-color: #F8F8F8;
  color: #585858;
}
.nav-list > li > a:focus {
  background-color: #F8F8F8;
  color: #1963AA;
}
.nav-list > li:hover > a {
  background-color: #FFF;
  color: #266cad;
}
.nav-list > li.open > a {
  background-color: #FAFAFA;
  color: #1963AA;
}
.nav-list > li.active > a {
  font-weight: bold;
  color: #2B7DBC;
}
.nav-list > li.active > a,
.nav-list > li.active > a:hover,
.nav-list > li.active > a:focus {
  background-color: #FFF;
}
.nav-list > li .submenu {
  background-color: #FFF;
  border-color: #E5E5E5;
}
.nav-list > li .submenu > li > a {
  border-top-color: #E4E4E4;
  background-color: #FFF;
  color: #616161;
}
.nav-list > li .submenu > li > a:hover {
  color: #4B88B7;
  background-color: #F1F5F9;
}
.nav-list > li .submenu > li.active > a {
  color: #2B7DBC;
}
.nav-list > li .submenu > li.active > a > .menu-icon {
  color: #C86139;
}
.nav-list > li .submenu > li.active.open > a > .menu-icon {
  color: inherit;
}
@media only screen and (min-width: 992px) {
  .nav-list > li .submenu > li.active.hover > a.dropdown-toggle > .menu-icon {
    color: inherit;
  }
}
.nav-list > li .submenu > li.active:not(.open) > a {
  background-color: #F5F7FA;
}
.nav-list > li .submenu > li.active:not(.open) > a:hover {
  background-color: #F1F5F9;
}
.nav-list > li > .submenu .open > a,
.nav-list > li > .submenu .open > a:hover,
.nav-list > li > .submenu .open > a:focus {
  border-color: #E4E4E4;
}
.nav-list > li > .submenu li > .submenu > li a {
  color: #757575;
}
.nav-list > li > .submenu li > .submenu > li a:hover {
  color: #4B88B7;
  background-color: #F1F5F9;
}
.nav-list > li > .submenu li.open > a {
  color: #4B88B7;
}
.nav-list > li > .submenu li > .submenu li.open > a,
.nav-list > li > .submenu li > .submenu li.active > a {
  color: #4B88B7;
}
.nav-list > li > .submenu:before,
.nav-list > li > .submenu > li:before {
  border-color: #9dbdd6;
}
.nav-list > li.active > .submenu:before,
.nav-list > li.active > .submenu > li:before {
  border-color: #8eb3d0;
}
.sidebar-toggle {
  background-color: #F3F3F3;
  border-color: #E0E0E0;
}
.sidebar-toggle > .ace-icon {
  border-color: #BBB;
  color: #AAA;
  background-color: #FFF;
}
.sidebar-shortcuts {
  background-color: #FAFAFA;
}
.sidebar-fixed .sidebar-shortcuts {
  border-color: #DDD;
}
.sidebar-shortcuts-mini {
  background-color: #FFF;
}
.nav-list li > .arrow:before {
  border-right-color: #B8B8B8;
  border-width: 10px 10px 10px 0;
  left: -10px;
}
.nav-list li > .arrow:after {
  border-right-color: #FFF;
  border-width: 10px 10px 10px 0;
  left: -9px;
}
.nav-list > li.pull_up > .arrow:after {
  border-right-color: #FFF !important;
}
.nav-list li.active > a:after {
  border-right-color: #2B7DBC;
}
.nav-list > li.active:after {
  display: block;
  content: "";
  position: absolute;
  right: -2px;
  top: -1px;
  bottom: 0;
  z-index: 1;
  border: 2px solid;
  border-width: 0 2px 0 0;
  border-color: #2B7DBC;
}
.sidebar-scroll .nav-list > li.active:after {
  right: 0;
}
@media only screen and (max-width: 991px) {
  .sidebar.responsive .nav-list > li.active.open > a:after,
  .sidebar.responsive-max .nav-list > li.active.open > a:after {
    display: block;
  }
  .sidebar.responsive .nav-list li li.active > a:after,
  .sidebar.responsive-max .nav-list li li.active > a:after {
    display: none;
  }
  .sidebar.responsive .nav-list > li.active:after,
  .sidebar.responsive-max .nav-list > li.active:after {
    height: 41px;
  }
}
.sidebar.menu-min .nav-list > li > a > .menu-text {
  background-color: #F5F5F5;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  border-color: #CCC;
}
.sidebar.menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
}
.sidebar.menu-min .nav-list > li.active > .submenu {
  border-left-color: #83B6D1;
}
.sidebar.menu-min .nav-list > li > .submenu {
  background-color: #FFF;
  border: 1px solid #CCC;
  border-top-color: #e6e6e6;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
}
.sidebar.menu-min .nav-list > li > .arrow:after {
  border-right-color: #F5F5F5;
  border-width: 8px 8px 8px 0;
  left: -8px;
}
.sidebar.menu-min .nav-list > li > .arrow:before {
  border-width: 8px 8px 8px 0;
  left: -9px;
}
.sidebar.menu-min .nav-list > li.active > .arrow:before {
  border-right-color: #5a9ec2;
}
.sidebar.menu-min .nav-list > li.active > a > .menu-text {
  border-left-color: #83B6D1;
}
.sidebar.menu-min .sidebar-shortcuts-large {
  background-color: #FFF;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  border-color: #CCC;
}
.sidebar.menu-min .sidebar-toggle > .ace-icon {
  border-color: #b1b1b1;
}
@media (max-width: 991px) {
  .sidebar.responsive-min .nav-list > li > a > .menu-text {
    background-color: #F5F5F5;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    border-color: #CCC;
  }
  .sidebar.responsive-min .nav-list > li > a.dropdown-toggle > .menu-text {
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  }
  .sidebar.responsive-min .nav-list > li.active > .submenu {
    border-left-color: #83B6D1;
  }
  .sidebar.responsive-min .nav-list > li > .submenu {
    background-color: #FFF;
    border: 1px solid #CCC;
    border-top-color: #e6e6e6;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.1);
  }
  .sidebar.responsive-min .nav-list > li > .arrow:after {
    border-right-color: #F5F5F5;
    border-width: 8px 8px 8px 0;
    left: -8px;
  }
  .sidebar.responsive-min .nav-list > li > .arrow:before {
    border-width: 8px 8px 8px 0;
    left: -9px;
  }
  .sidebar.responsive-min .nav-list > li.active > .arrow:before {
    border-right-color: #5a9ec2;
  }
  .sidebar.responsive-min .nav-list > li.active > a > .menu-text {
    border-left-color: #83B6D1;
  }
  .sidebar.responsive-min .sidebar-shortcuts-large {
    background-color: #FFF;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    border-color: #CCC;
  }
  .sidebar.responsive-min .sidebar-toggle > .ace-icon {
    border-color: #b1b1b1;
  }
}
@media only screen and (min-width: 992px) {
  .nav-list li.hover > .submenu {
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    border-color: #CCC;
  }
  .nav-list li.hover > .submenu > li.active > a {
    background-color: #F5F5F5;
  }
  .nav-list li.hover > .submenu > li:hover > a {
    background-color: #EEF3F7;
    color: #2E7DB4;
  }
}
@media only screen and (min-width: 992px) and (max-width: 991px) {
  .sidebar.navbar-collapse .nav-list li li.hover.active.open > a {
    background-color: #FFF;
  }
  .sidebar.navbar-collapse .nav-list li li.hover:hover > a {
    background-color: #FFF;
  }
  .sidebar.navbar-collapse .nav-list li li.hover > a:hover,
  .sidebar.navbar-collapse .nav-list li li.hover.open > a:hover,
  .sidebar.navbar-collapse .nav-list li li.hover.open.active > a:hover {
    background-color: #F1F5F9;
  }
  .sidebar.navbar-collapse .nav-list > li .submenu > li.active.hover > a.dropdown-toggle > .menu-icon {
    color: #C86139;
  }
  .sidebar.navbar-collapse .nav-list > li .submenu > li.active.open.hover > a.dropdown-toggle > .menu-icon {
    color: inherit;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a {
    color: #585858;
  }
  .sidebar.navbar-collapse .nav-list > li.open.hover:not(:hover):not(:focus):not(.active) > a > .arrow {
    color: inherit;
  }
  .sidebar.navbar-collapse .nav-list > li.open.hover:hover > a {
    background-color: #FFF;
  }
  .sidebar.navbar-collapse .nav-list > li > .submenu li.open.hover:not(:hover):not(:focus):not(.active) > a {
    color: #616161;
  }
  .sidebar.navbar-collapse .nav-list > li > .submenu li.open.hover:not(:hover):not(:focus):not(.active) > a > .arrow {
    color: inherit;
  }
}
@media only screen and (min-width: 992px) and (max-width: 991px) {
  .sidebar.navbar-collapse .nav-list li.hover > .submenu {
    border-top-color: #E5E5E5;
    background-color: #FFF;
  }
  .nav-list li.hover > .submenu > li.active:not(.open) > a {
    background-color: #F5F7FA;
  }
  .nav-list li.hover > .submenu > li.active:not(.open) > a:hover {
    background-color: #F1F5F9;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar.h-sidebar {
    background-color: #F8F8F8;
  }
  .sidebar.h-sidebar:before {
    background-color: #E4E4E4;
    -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05) inset;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.05) inset;
  }
  .sidebar.h-sidebar .nav-list > li:hover,
  .sidebar.h-sidebar .nav-list > li:hover + li {
    border-left-color: #CEDDE5;
  }
  .sidebar.h-sidebar .nav-list > li:last-child:hover {
    border-right-color: #CEDDE5;
  }
  .sidebar.h-sidebar .nav-list > li.active,
  .sidebar.h-sidebar .nav-list > li.active + li,
  .sidebar.h-sidebar .nav-list > li:hover + li.active {
    border-left-color: #79B0CE;
  }
  .sidebar.h-sidebar .nav-list > li.active:last-child {
    border-right-color: #79B0CE;
  }
  .sidebar.h-sidebar .nav-list > li.active:before {
    background-color: #2B7DBC;
  }
  .sidebar.h-sidebar .nav-list > li.active > a:after {
    border-width: 0 0 2px 0;
    border-color: transparent;
    border-bottom-color: #FFF;
    left: 0;
    right: 0;
    top: auto;
    bottom: -2px;
  }
  .sidebar.h-sidebar .sidebar-shortcuts-large {
    background-color: #FFF;
    border-color: #CCC;
    -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
    box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.1);
  }
  .sidebar.h-sidebar .sidebar-shortcuts-large:after {
    border-bottom-color: #FFF;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .submenu {
    border-color: #CCC;
  }
  .sidebar.h-sidebar.menu-min .nav-list > li.hover > .submenu {
    border-top-color: #e6e6e6;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .arrow:after {
    border-color: transparent;
    border-bottom-color: #FFF;
  }
  .sidebar.h-sidebar .nav-list > li.hover > .arrow:before {
    border-color: transparent;
    border-bottom-color: #B8B8B8;
  }
  .sidebar.h-sidebar.menu-min .nav-list > li.hover > .arrow:after {
    border-bottom-color: #F5F5F5;
  }
  .sidebar.h-sidebar.menu-min .nav-list > li.active > a > .menu-text {
    border-left-color: #CCC;
  }
}
@media only screen and (max-width: 991px) {
  .sidebar {
    border-width: 0 1px 1px 0;
    border-top-color: #d6d6d6;
  }
  .menu-toggler + .sidebar.responsive {
    border-top-width: 1px;
  }
  .sidebar.responsive-min {
    border-width: 0 1px 0 0;
  }
  .sidebar.navbar-collapse {
    border-width: 0;
    border-bottom-width: 1px !important;
    border-bottom-color: #CCC;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1) !important;
  }
  .sidebar.navbar-collapse.menu-min .nav-list > li > .submenu {
    background-color: #FFF;
  }
}
.sidebar-scroll .sidebar-shortcuts {
  border-bottom-color: #dddddd;
}
.sidebar-scroll .sidebar-toggle {
  border-top-color: #dddddd;
}
.main-container .menu-toggler {
  background-color: #444;
}
.main-container .menu-toggler:before {
  border-top-color: #87B87F;
  border-bottom-color: #6FB3E0;
}
.main-container .menu-toggler:after {
  border-top-color: #FFA24D;
  border-bottom-color: #D15B47;
}
.main-container .menu-toggler > .toggler-text {
  border-top-color: #444;
}
.nav-list > li.disabled:before {
  display: none !important;
}
.nav-list > li.disabled > a {
  background-color: #ebebeb !important;
  color: #656565 !important;
}
.nav-list li .submenu > li.disabled > a,
.nav-list li.disabled .submenu > li > a {
  background-color: #f2f2f2 !important;
  color: #7a7a7a !important;
  cursor: not-allowed !important;
}
.nav-list li .submenu > li.disabled > a > .menu-icon,
.nav-list li.disabled .submenu > li > a > .menu-icon {
  display: none;
}
.nav-list li.highlight li.active > a:after,
.nav-list li li.highlight.active > a:after,
.nav-list li.highlight li.active > a:before,
.nav-list li li.highlight.active > a:before {
  display: none;
}
.nav-list > li.highlight.active > a:after,
.nav-list > li.highlight.active > a:before {
  display: block;
}
.nav-list > li.highlight:hover,
.nav-list > li.highlight.active {
  border-color: #C8D8E2;
}
.nav-list > li.highlight:hover + li,
.nav-list > li.highlight.active + li {
  border-top-color: #C8D8E2;
}
.nav-list > li.highlight:hover > a,
.nav-list > li.highlight.active > a {
  background-color: #FFF;
}
.nav-list > li.highlight.active:after {
  display: none;
}
.nav-list > li.highlight.active:before {
  display: block;
  background-color: #4f90c2;
}
.nav-list > li.highlight.active > a {
  background-color: #F2F6F9 !important;
}
.nav-list > li.highlight.active > a:before,
.nav-list > li.highlight.active > a:after {
  display: block;
  content: "";
  position: absolute;
  top: -1px;
  right: -9px;
  bottom: auto;
  z-index: 1;
  border-style: solid;
  border-width: 20px 0 21px 10px;
  border-color: transparent;
}
.nav-list > li.highlight.active > a:before {
  border-left-color: #a0bccd;
  right: -10px;
}
.nav-list > li.highlight.active > a:after {
  border-left-color: #F2F6F9;
}
.nav-list li li.highlight.active > a {
  background-color: #F2F6F9;
}
.nav-list li li.highlight.active.open > a {
  background-color: #FFF;
}
.nav-list li li.highlight.active.open > a:hover {
  background-color: #F1F5F9;
}
@media (min-width: 992px) {
  .sidebar.h-sidebar .nav-list > li.highlight.active > a:after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    right: auto;
    bottom: -2px;
    left: 50%;
    margin-left: -7px;
    border-color: transparent;
    border-width: 8px 7px;
    border-bottom-color: #FFF;
  }
  .sidebar.h-sidebar .nav-list > li.highlight.active > a:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-left: -7px;
    border-width: 8px;
    border-color: transparent;
    border-bottom-color: #74add7;
  }
  .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:after,
  .sidebar.h-sidebar .nav-list > li.highlight.active.hover-show > a:after,
  .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:before,
  .sidebar.h-sidebar .nav-list > li.highlight.active.hover-show > a:before {
    display: none;
  }
}
@media (min-width: 992px) {
  .sidebar.compact .nav-list li.highlight.active > a:after,
  .sidebar.compact .nav-list li.highlight.active > a:before {
    display: none;
  }
  .sidebar.compact.menu-min .nav-list li.highlight.active > a:after,
  .sidebar.compact.menu-min .nav-list li.highlight.active > a:before {
    display: block;
  }
}
@media (min-width: 992px) {
  .sidebar.h-sidebar .nav-list li.highlight.active > a:after,
  .sidebar.h-sidebar .nav-list li.highlight.active > a:before {
    display: block;
    border-width: 8px !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }
  .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:after,
  .sidebar.h-sidebar .nav-list > li.highlight.active:hover > a:before {
    display: none !important;
  }
}
.sidebar-scroll .nav-list > li.active.highlight > a:after,
.sidebar-scroll .nav-list > li.active.highlight > a:before {
  display: none !important;
}
