tr.is-row-form-inline.not-editable td input {
  border: unset;
  background-color: transparent !important;
}

.h-100vh {
  height: 100vh;
}

.lt-cell .btn {
  padding: 0.2rem;
  height: 2rem;
  width: 2rem;
}

.paginate_button {
  @extend .page-item;
}

.paginate_button a {
  @extend .page-link;
}

.ember-light-table {
  margin-bottom: 1rem;

  // Override addon.
  .lt-cell {
    overflow: auto;
    text-overflow: unset;
    white-space: unset;
  }
}

.dataTables_wrapper .row:first-child {
  width: 100%;
  margin-bottom: 1rem;
}

.dataTables_wrapper .col-sm-2 {
  text-align: right;
}

.dataTables_wrapper .btn-xs {
  padding: 0.25rem;
}

.dataTables_wrapper .row:first-child .col-sm-10:first-child {
  @extend .row;
  margin: 0;

  .col-sm-2 {
    text-align: left;
  }

  .form-control {
    width: 100%;
  }

  select.form-control {
    width: calc(100% - 24px)
  }
}

.table-total-row {
  font-weight: bold;
}
