.dropdown-item-noactive:active {
  color: inherit !important;
  background-color: unset;
}

.dropdown-input {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

// Power select
.ember-power-select-selected-item {
  white-space: nowrap;
}
