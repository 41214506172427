$primary: #2A4264;

$secondary: #595959;

$theme-colors: (
  "primary-dark": #2263C0,
  "light-gray": #ecf0f3
);

$font-size-base: 0.75rem;

@import "ember-bootstrap/bootstrap";
@import "ember-power-select";
@import "ember-power-select/themes/bootstrap";
@import "servir-ember-utilities";
@import "font";
@import "typography";
@import "layout"; // Sidebar & main layout
@import "dropdown";
@import "theme_table";
@import "table";
@import "dashboard";
@import "ace-sidebar";
@import "app-sidebar";
@import "toast";

.sidebar-form {
  position: relative;
  top: 0;
  right: 0;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}

// Display always
.invalid-feedback {
  display: inline-block !important;
}

// We were unable to add the class "is-invalid" to the components of "ember-form-for".
.is-invalid {
  input,
  select,
  textarea {
    border-color: #dc3545;
  }
}

// Components

#login-container {
  background: url('/images/office.jpg');
  background-position: center;
  background-size: cover;
}

@include media-breakpoint-up(md) {
  #login-form {
      min-width: 550px;
  }
}

#image-error {
  max-width: 460px;
}

aside#sidebar div.profile-container {
  width: calc(250px - 3rem);
}

.ember-basic-dropdown-content {
  z-index: 1100;
}

.btn-xs {
  @extend .btn-sm;
}

.no-padding {
  @extend .p-0;
}

.input-sm {
  @extend .form-control-sm;
}

.header {
  line-height: 28px;
  margin-top: 24px;
  margin-bottom: 16px;
  border-bottom: 1px solid #CCC;

  &.blue {
    border-bottom-color: #d5e3ef;
  }
}

.tab-content {
  border: 1px solid #C5D0DC;
  padding: 16px 12px;
  position: relative;
}

.modal-title {
  flex-grow: 1;
}

.module-header {
  .actions-container {
    display: flex;
  }

  .select-field {
    width: 150px;
    display: inline-block;
    margin: 0 !important;

    select {
      font-size: 16px;
      padding: 0 6px;
      height: 32px;
    }
  }

  .ember-power-select-trigger {
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    border-color: #212529;

    .ember-power-select-placeholder {
      color: #212529;
      font-weight: 400;
    }
  }

  .current-year-month {
    line-height: 32px;
    font-weight: 400;
  }
}

.ember-power-select-trigger--active, .ember-power-select-trigger.no-radius.form-control.ember-basic-dropdown-trigger.ember-basic-dropdown-trigger--left.ember-basic-dropdown-trigger--below {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #567eb8 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem #2a426440 !important;
}

.report-card {
    padding: 10px 20px;
    text-decoration: none !important;
    cursor: pointer;
}

.kar-parametro-modal {
  .generales {
    .checkbox-field {
      margin-bottom: 0 !important;
    }
  }
}
