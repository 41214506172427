$sidebar-bg: #343a40;  // bg-dark
$sidebar-color: $gray-400;
$sidebar-hover-bg: #343a40;
$sidebar-hover-color: #266cad;

.sidebar {
  a {
    color: #fff;
    text-decoration: none;
  }
}

.nav-list {
  li {
    a {
      color: $sidebar-color !important;
      background-color: $sidebar-bg !important;
    }

    &:hover > a {
      color: $sidebar-hover-color !important;
      background-color: $sidebar-hover-bg !important;
    }

    .submenu {
      color: $sidebar-color !important;
      background-color: $sidebar-bg !important;
    }
  }
}

.nav > li > a {
  padding: 10px 15px;
}
