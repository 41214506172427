table {
  @extend .table;
}

.thead-gray, .thead-gray tr th {
  background-color: #ecf0f3;
  border: unset !important;
}

.form-inline-intable .form-control {
  width: 100%;
}

.table-border-top-0 thead tr th {
  border-top: 0 !important;
}

.selected-rows-container {
  background-color: $primary;
  color: white;
  padding: 1rem;
  padding-bottom: 2rem;
  border-radius: 8px 8px 0 0;
  margin-bottom: -1rem;
  z-index: -1;
  display: flex;
}

.ember-table {
  @extend .rounded-lg;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  margin-bottom: 0;

  td {
    background-color: white;
  }

  .et-reorder-main-indicator {
    position: absolute;
    background: white;
    border-left: none;
    border-right: none;
    opacity: 0.8;
    z-index: 10;
    height: 100%;
    width: 100%;

    th {
      background: #e9f4fc;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      padding: $table-cell-padding !important;
    }
  }

  .et-reorder-drop-indicator {
    position: absolute;
    background: 0 0;
    border-left: none;
    border-right: $dark solid 1px;
    opacity: 0.8;
    z-index: 10;
    height: 100%;
  }

  .is-reordering.ember-table * {
    cursor: grabbing !important;
    user-select: none;
  }
}
