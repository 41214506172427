body {
  background-color: #f2f2f2;
}

.wrapper {
  display: flex;
  width: 100%;
}

aside#sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  transition: all 0.3s;

  .nav {
    button {
      width: 100%;
      display: block;
      line-height: 2rem;
      height: 2rem;
      color: $gray-400;
    }

    button {
      .bi-chevron-up {
        display: none;
      }

      .bi-chevron-down {
        display: block;
      }
    }

    button:hover {
      color: white;
    }

    button.bg-dark:focus,
    button.bg-dark:hover {
      background: inherit !important;
      border: unset;
      outline: unset;
    }

    button.is-open {
      .bi-chevron-up {
        display: block;
      }

      .bi-chevron-down {
        display: none;
      }
    }
  }
}

aside#sidebar.collapsed {
  width: 80px;
}

main#content {
  padding-left: 250px;
  width: 100%;
}

main#content.collapsed {
  padding-left: 80px;
  width: 100%;
}
